.register {
	padding: 120px 0 0;
	background-color: $white;
  @media #{$up640}{
    padding: 165px 0 0;
  }
  @media #{$up768}{
    padding: 170px 0 0;
  }
  @media #{$up1220}{
    padding: 180px 0 0;
  }
  @media #{$up1600}{
    padding: 220px 0 0;
  }
  .wrap {
    @media #{$up1220}{
      padding-left: 96px;
    }
  }

  .nav {
    @media #{$up640}{
      text-align: left;
    }
    .wrap {
      @media #{$up1220}{
        padding: 0 0 0 45px;
      }
    }
  }

	header {
    margin: 0;
    padding: 10px 0 0;
    @media #{$up640}{
      margin: 0 0 30px;
    }
    @media #{$up768}{
      margin: 0 0 40px;
    }
    @media #{$up1220}{
      margin: 0 0 60px;
    }
		h2 {
      min-height: 62px;
			@include font(2);
      @media #{$up640}{
        @include font(3);
      }
      @media #{$up1220}{
        @include font(4.8);
      }
      &:before {
        top: -5px;
        @media #{$up640}{
          top: -7px;
        }
        @media #{$up1220}{
          top: -9px;
        }
      }
      @media #{$up1220}{
        top: -9px;
        left: -96px;    
      }
		}
	}

  fieldset {
    overflow: hidden;
    padding: 30px 0;
    @media #{$up640}{
      padding: 50px 0;
    }
    @media #{$up1220}{
      padding: 60px 0 60px 92px;
    }

  }
  .row {
    position: relative;
    clear:both;
  }
  .row--input {
    width: 100%;
    max-width: 444px;
  }
  .row--offbot {
    margin: 0 0 40px;
  }
  .row[data-option]{
    padding: 0 0 0 40px;
    margin: 0 0 60px;
    p {
      margin: 0 0 1.4em;
    }
    .checbox,
    .radio {
      p {
        margin: 0;
      }
    }
  }
  .row--col {
    clear: none;
    @media #{$up768}{
      width: 50%;
      float: left;
      &:nth-child(2n){
        padding-left: 20px;
      }
      &:nth-child(2n-1){
        clear: left;
        padding-right: 20px;
      }
    }
  }

  button {
    margin: 0;
  }

  p.panel-error {
    display: none;
    padding: 10px 0 20px 0;
    color: red;
  }

  textarea {
    height: 200px;
    padding-top: 15px
  }

  [data-option]{
    display: none;
  }

}