.checkbox,
.radio {
  position: relative;
  padding: 0 0 1em;
  color: $text;
  font-family: 'akrobatregular';
  &.disabled {
    .checkbox__content,
    .radio__content,
    p {
      color: $gray-text !important;
    }
  }
  i {
    position: absolute;
    z-index: 2;
    left: 0;
    top: -2px;
    width: 22px;
    height: 22px;
    background-color: $white;
    border: 1px solid #BBBBBB;
    font-size: 15px;
    text-align: center;
    line-height: 21px;
    &:before {
      opacity: 0;
    }
  }
  input {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    opacity: 0;
    &:checked ~ i:before {
      opacity: 1;
    }
    &.error ~ i {
      border-color: red !important;
    }
    &.error ~ i ~ .checkbox__content {
      color: red !important;
    }
    &.error ~ i ~ .radio__content {
      .panel-error {
        display: block;
      }
    }
  }
  label.error {
    display: none !important;
  }
  p {
    line-height: 1.4em;
  }

  a {
    font-family: 'akrobatbold';
    &:hover {
      color: $brand;
    }
  }

  &--small {
    p {
      @include font(1.4);
    }
  }

  &--large {
    p:nth-child(1){
      margin: 0;
      font-family: 'akrobatextrabold';
      @include font(2);
      @media #{$up640}{
        @include font(2.2);
      }
      @media #{$up768}{
        @include font(2.4);
      }
    }
    p:nth-child(2){
      color: $brand;
    }
    i {
      top: 5px;
    }
  }

  &--sub {
    margin-left: 40px;
  }

}


.checkbox__content,
.radio__content {
  padding: 0 0 0 40px;
}