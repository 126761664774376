.speakers {
  background-color: $white;
  margin: -50px 0 0;
  .wrap--more {
    text-align: center;
    padding-top: 0px;
    height: 120px;
    @media #{$up1024}{
      height: 240px;
      padding-top: 70px;
    }
  }
  .btn {
    min-width: 200px;
  }
}

.speaker {
  position: relative;
  z-index: 10;
  margin: 0 auto 30px;
  max-width: 300px;
  @media #{$up640}{
    max-width: 100%;
    margin: 0;
    padding: 40px 0;
  }
  @media #{$up1024}{
    padding: 0;
    margin: 0 0 60px 0;
  }
  @media #{$up1220}{
    margin: 0 0 100px 0;
  }
  &:first-child {
    @media #{$up640}{
      padding-top: 0;
    }
  }
  &:nth-child(even){
    .wrap {
      flex-direction: row-reverse;
    }
    .speaker__img {
      @media #{$up1024}{
        left: auto;
        right: 92px;
      }
      @media #{$up1220}{
        right: 85px;
      }
      @media #{$up1600}{
        right: 92px;
      }
    }
    .speaker__dsc {
      @media #{$up640}{
        padding: 0 30px 0 0;
      }
      @media #{$up1024}{
        padding: 0 140px 40px 0;
      }
      @media #{$up1220}{
        padding: 0 160px 40px 0;
      }
      @media #{$up1600}{
        padding: 0 180px 40px 0;
      }
    }
  }
  &:last-child {
    &:after {
      display: none;
    }
    @media #{$up640}{
      margin: 0;
    }
  }
  &:after {
    position: absolute;
    width: 100%;
    height: 2px;
    content: '';
    left: 0;
    bottom: 0;
    background-color: $gray-bgr;
    @media #{$up1024}{
      bottom: 40px;
    }
    @media #{$up1220}{
      bottom: 50px;
    }
  }
  .wrap {
    @media #{$up640}{
      display: flex;
      align-items: center;
    }
  }
}
.speaker__img {
  position: relative;
  z-index: 2;
  left: 0;
  width: 100%;
  height: 0;
  padding: 0 0 100%;
  margin: 0 0 20px;
  display: block;
  background-position: center;
  background-size: cover;
  background-color: $gray-bgr;
  @media #{$up640}{
    padding: 0;
    width: 240px;
    height: 240px;
    min-width: 240px;
    margin: 0;
  }
  @media #{$up768}{
    width: 300px;
    height: 300px;
    min-width: 300px;
  }
  @media #{$up1024}{
    left: 92px;
  }
  @media #{$up1220}{
    width: 444px;
    min-width: 444px;
    height: 444px;
    left: 85px;
  }
  @media #{$up1600}{
    left: 92px;
  }
}
.speaker__dsc {
  position: relative;
  z-index: 2;
  @media #{$up640}{
    padding: 0 0 0 30px;
  }
  @media #{$up1024}{
    padding: 0 0 40px 140px;
  }
  @media #{$up1220}{
    width: 644px;
    padding: 0 0 40px 160px;
  }
  @media #{$up1600}{
    padding: 0 0 40px 180px;
  }
  p {
    @include font(1.6);
    @media #{$up768}{
      @include font(2);
    }
    @media #{$up1220}{
      @include font(2.4);
    }
  }
}
.speaker__data {
  position: relative; 
  padding-left: 50px;
  padding-bottom: 30px;
  @media #{$up640}{
    padding-bottom: 0;
  }
  @media #{$up768}{
    padding-left: 64px;
  }
  @media #{$up1220}{
    padding-left: 75px;
  }
  &:before {
    position: absolute;
    top: 3px;
    left: 0;
    width: 40px;
    height: 40px;
    background-color: $brand;
    content: '';
    @media #{$up768}{
      width: 54px;
      height: 54px;
    }
    @media #{$up1220}{
      width: 63px;
      height: 63px;
    }
  }
  h4 {
    margin: 0 0 2px;
    @include font(1.8);
    @media #{$up768}{
      @include font(2);
    }
    @media #{$up1220}{
      @include font(2.4);
    }
    a {
      margin: 0 0 2px;
      @include font(1.8);
      color: $text;
      @media #{$up1220}{
        @include font(2.4);
      }
    }
  }
  p {
    margin: 0;
    @include font(1.4);
    font-family: 'akrobatlight';
  }
  a {
    color: $gray-text;
    @include font(1.4);
    &:hover {
      color: $brand;
    }
  }
}

.cta {
  position: relative;
  z-index: 1;
  margin: -72px 0 0 0;
  padding: 100px 0 40px;
  overflow: hidden;
  background-color: $brand;
  background-image: url(../images/photos/bgr-cta.jpg);
  background-position: center;
  background-size: cover;
  color: $white;
  text-align: center;
  @media #{$up640}{
    padding: 120px 0 60px;
    margin: -100px 0 0 0;
    text-align: left;
  }
  @media #{$up1024}{
    padding: 150px 0 100px;
  }
  @media #{$up1220}{
    padding: 170px 0 120px;
    margin: -110px 0 0 0;
  }
  &:before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: '';
    top: 0;
    left: 0;
    @include bgr-alpha($brand,0.9);
  }
  .wrap {
    position: relative;
    z-index: 2;
    font-size: 0;
  }
  .col {
    @media #{$up640}{
      display: inline-block;
      vertical-align: bottom;
    }
    &:first-child {
      @media #{$up640}{
        width: 65%;
        padding-left: 40px;
      }
      @media #{$up1024}{
        padding-left: 80px;
      }
    }
    &:last-child {
      margin-top: 20px;
      @media #{$up640}{
        width: 35%;
        text-align: right;
      }
      @media #{$up768}{
        padding-right: 40px;
      }
      @media #{$up1024}{
        padding-right: 60px;
      }
    }
    .btn {
      display: inline-block;
      width: auto;
      @include font(1.6);
      @media #{$up640}{
        @include font(2);
      }
      @media #{$up768}{
        @include font(2.4);
      }
      @media #{$up1220}{
        @include font(3);
        line-height: 72px;
        height: 72px;
        padding: 0 40px;
      }
    }
  }
  h3 {
    margin: 0;
    @include font(2);
    font-family: 'akrobatextrabold';
    @media #{$up640}{
      @include font(2.4);
    }
    @media #{$up768}{
      @include font(3.4);
    }
    @media #{$up1220}{
      @include font(4);
    }
  }
  p {
    @include font(1.8);
    @media #{$up768}{
      @include font(2.4);
    }
    @media #{$up1220}{
      @include font(3);
    }
  }
  .agenda-download p {
    @include font(1.8);
  }
}