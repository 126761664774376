.agenda {
	position: relative;
	z-index: 2;
	@media #{$up1024}{
    margin: -50px 0 0 0;
  }
	&:before {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		width: 100%;
		height: 60px;
		background-color: $gray-bgr;
		content: '';
	}
	.header {
    padding: 15px 0;
    @media #{$up640}{
      padding: 40px 0;
    }
    @media #{$up1024}{
      padding: 60px 0;
    }
		@media #{$up1220}{
      padding: 100px 0;
    }
	}
	.btn--arrow {
		width: 100%;
    line-height: 50px;
    text-align: center;
    @media #{$up640}{
      width: 50px;
    }
    @media #{$up1220}{
      width: 76px;
      height: 76px;
      line-height: 76px;
      font-size: 30px;
    }
		i {
			position: relative;
			top: 3px;
			transition: transform .2s $ease_out;
		}
	}

  &--archive {
    margin: 0;
    padding: 30px 0;
    @media #{$up640}{
      padding: 30px 0 70px;
    }
    @media #{$up768}{
      padding: 40px 0 80px;
    }
    @media #{$up1220}{
      padding: 0 0 100px;
    }
  }

}

.agenda-item {
	position: relative;
	z-index: 100;
	background-color: $white;
	margin: 0 0 10px;
	h3 {
		@include font(2);
		margin: 0;
    @media #{$up768}{
      @include font(3);
    }
    @media #{$up1220}{
      @include font(4);
    }
	}
  &.active {
    .agenda-item__header {
      .area {
        display: none;
      }
      .btn--arrow i {
        transform: rotate(-180deg);
      }
    }
  }
}

.agenda-item__header {
  position: relative;
  padding: 20px;
  @media #{$up640}{
    padding: 30px;
  }
  @media #{$up1024}{
    padding: 40px;
  }
	@media #{$up1220}{
    padding: 60px;
  }
	.row--flex {
		@media #{$up640}{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
	}
	.col {
		@media #{$up640}{
      display: inline-block;
      width: auto;
    }
	}
	.col:nth-child(1){
    padding-bottom: 15px;
    @media #{$up640}{
      width: 42%;
      padding-bottom: 0;
      padding-right: 20px;
      text-align: right;
      border-right: 3px solid $brand;
    }
    @media #{$up1024}{
      width: 350px;
      padding-right: 60px;
    }
	}
	.col:nth-child(2){
    padding-bottom: 15px;
		@media #{$up640}{
      width: 60%;
      padding-bottom: 0;
      padding-left: 20px;
      padding-right: 20px;
    }
    @media #{$up1024}{
      width: 45%;
      padding-left: 0;
      padding-right: 0;
    }
	}
	.col:nth-child(3){
    @media #{$up640}{
      text-align: right;
    }
	}
	p {
    @include font(1.6);
    @media #{$up768}{
      @include font(1.8);
    }
		@media #{$up1220}{
      @include font(2.4);
    }
	}
	time {
		display: block;
	}
	time:nth-child(1){
		@include font(2.4);
		text-transform: uppercase;
		font-family: 'akrobatbold';
    @media #{$up640}{
      @include font(2.4);
    }
    @media #{$up1220}{
      @include font(4);
    }
	}
	time:nth-child(2){
		color: $brand;
		@include font(1.6);
    @media #{$up640}{
      @include font(2);
    }
    @media #{$up1220}{
      @include font(2.4);
    }
	}
  &:hover {
    .btn--arrow:before {
      transform-origin: right top;
      transform: scale(1,1);
    }
  }
}

.agenda-item__content {
  position: relative;
  display: none;
  padding: 0 20px 20px;
  @media #{$up640}{
    padding: 30px;
  }
  @media #{$up1024}{
    padding: 40px;
  }
  @media #{$up1220}{
    padding: 60px;
  }
  .col:nth-child(1){
    display: none;
    @media #{$up768}{
      position: absolute;
      height: 100%;
      height: calc(100% - 60px);
      width: 10%;
      width: 80px;
      padding-top: 44px;
      float: left;
      display: block;
    }
    @media #{$up1024}{
      height: 100%;
      height: calc(100% - 80px);
    }
    @media #{$up1220}{
      height: 100%;
      height: calc(100% - 120px);
    }
    a:last-child {
      margin: 0;
    }
  }
  .col:nth-child(2){
    @media #{$up768}{
      width: 90%;
      width: calc(100% - 80px);
      padding-left: 40px;
      float: right;
    }
    @media #{$up1024}{
      width: 85%;
      padding-left: 100px;
    }
  }
  .btn--day {
    margin: 0 0 15px;
  }
  .btn--arrow {
    margin: 35px 0 0 0;
    float: right;
  }
}

.time-item {
  padding: 25px 0;
  border-bottom: 2px solid $gray-bgr;
  @media #{$up768}{
    padding: 35px 0;
    border-width: 3px;
  }
  &:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
  }
  .col-2:nth-child(1){
    width: 20%;
    float: left;
    @media #{$up640}{
      width: 35%;
    }
  }
  .col-2:nth-child(2){
    width: 80%;
    float: right;
    @media #{$up640}{
      width: 65%;
    }
  }
  time {
    color: $brand;
    @include font(2);
    line-height: 1.1em;
    font-family: 'akrobatbold';
    @media #{$up640}{
      @include font(3);
    }
    @media #{$up1220}{
      @include font(4.8);
    }
  }
  p {
    padding: .7em 0 0 0;
    @media #{$up1220}{
      @include font(3);
    }
  }

  .time-item__step {
    margin: 0;
    padding: 0;
    @include font(2);
    line-height: 1.1em;
    font-family: 'akrobatbold';
    @media #{$up640}{
      @include font(3);
    }
    @media #{$up1220}{
      padding: .15em 0 0 0;
      @include font(3.6);
    }
  }

  .time-item__title {
    margin: 0;
    padding: 0 0 0 0;
    color: $brand;
    @include font(2);
    line-height: 1.1em;
    font-family: 'akrobatblack';
    @media #{$up640}{
      @include font(3);
    }
    @media #{$up1220}{
      padding: .18em 0 0 0;
      @include font(3.6);
    }
  }

  .time-item__speaker {
    position: relative;
    margin: 0 0 25px;
    @media #{$up768}{
      margin: 0 0 40px;
    }
    h3 {
      @include font(1.8);
      @media #{$up640}{
        @include font(2);
      }
      @media #{$up1220}{
        @include font(3);
      }
    }
    p {
      padding: 0;
      margin: 0;
      @include font(1.6);
      font-family: 'akrobatlight';
    }
    .img {
      margin: 0 0 10px;
      width: 44px;
      height: 44px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: $gray-bgr;
      @media #{$up640}{
        position: absolute;
        top: 0;
        left: -60px;
      }
      @media #{$up1220}{
        top: 50%;
        left: -100px;
        margin-top: -38px;
        width: 76px;
        height: 76px;
      }
    }
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.agenda-download {
  position: relative;
  text-align: center;
  color: $white;
  margin: 0 0 30px;
  @media #{$up640}{
    text-align: right;
    padding-right: 30px;
    margin: 0 0 50px;
  }
  @media #{$up768}{
    margin: 0 0 70px;
  }
  @media #{$up1024}{
    top: -20px;
    padding-right: 40px;
  }
  @media #{$up1220}{
    padding-right: 60px;
  }
  p {
    margin: 0 0 30px;
    @include font(1.8);
    @media #{$up640}{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0 0 10px;
    }
  }
  span {
    display: block;
    font-size: inherit;
  }
  .btn {
    margin: 10px 0 0;
    @media #{$up640}{
      margin: 0 0 0 15px;
    }
    /*margin-left: 15px;*/
  }
}