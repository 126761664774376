@import "normalize";
@import "fonts";
@import "variables";
@import "mixins";
@import "settings";

@import "general";
@import "plugins";
@import "components/_buttons.scss";
@import "components/_misc.scss";
@import "components/_table.scss";
@import "components/_typo.scss";
@import "forms/_elements.scss";
@import "forms/_radio_checkbox.scss";
@import "forms/_select.scss";
@import "parts/_footer.scss";
@import "parts/_header.scss";
@import "parts/_navbar.scss";
@import "chunks/_cookies-consent.scss";
@import "pages/_about.scss";
@import "pages/_agenda.scss";
@import "pages/_archive.scss";
@import "pages/_contact.scss";
@import "pages/_home.scss";
@import "pages/_partners.scss";
@import "pages/_register.scss";
@import "pages/_speaker.scss";

// iOS font autoscale fix
@media screen and (max-device-width: 480px) {
	body {
		-webkit-text-size-adjust: none;
	}
}     