

.btn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 44px;
  line-height: 44px;
  padding: 0 18px;
  overflow: hidden;
  text-transform: uppercase;
  background-color: $brand;
  transition: color .3s linear;
  color: $white;
  font-family: 'akrobatbold';
  @media #{$up768}{
    height: 50px;
    line-height: 50px;
    padding: 0 24px;
  }
  &:before {
    position: absolute;
    z-index: 1;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    transform-origin: left top;
    transform: scale(0,1);
    transition: transform 0.5s $ease_out;
    background-color: $text;
    pointer-events: none;
  }
  span {
    position: relative;
    z-index: 2;
  } 
  &:hover {
    &:before {
      transform-origin: right top;
      transform: scale(1,1);
    }
  }

  &--dark {
    background-color: $text;
    &:before {
      background-color: $white;
    }
    &:hover {
      color: $text;
    }
  }

  &--loading {
    pointer-events: none;
    cursor: default;
    color: transparent;
    transition: none;
    &:after {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      margin: -14px 0 0 -14px;
      z-index: 10;
      width: 18px;
      height: 18px;
      border: 3px solid $white;
      border-left-color: transparent;
      content: '';
      border-radius: 50%;
      animation: spin .4s linear infinite;
    }
  }

}

.btn--menu {
  width: 44px;
  margin: 0 0 0 15px;
  padding: 0;
  background-color: $text;
  @media #{$up768}{
    width: 50px;
    margin: 0 0 0 20px;
  }
  @media #{$up1220}{
    margin: 0 0 0 30px;
  }
  &:before {
    background-color: $white;
  }
  i {
    position: relative;
    z-index: 2;
    font-size: 20px;
    @media #{$up768}{
      top: 1px;
      left: 1px;
    }
  }
  &:hover {
    color: $brand;
  }
}

.btn--arrow {
  width: 50px;
  height: 50px;
  padding: 0;
  text-align: center;
  i {
    display: inline-block;
    position: relative;
    z-index: 2;
  }
}

.btn--day {
  width: 76px;
  height: 76px;
  line-height: 76px;
  padding: 0;
  text-align: center;
  @include font(2.4);
  &.active {
    pointer-events: none;
    cursor: default;
    &:before {
      transform-origin: right top;
      transform: scale(1,1);
    }
  }
}


button.btn {
   line-height: 1;
}

.read-more {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 0 0 0 24px;
  color: $brand;
  &:before {
    position: absolute;
    top: 3px;
    left: 0;
    width: 16px;
    height: 16px;
    background-color: $brand;
    content: '';
  }
  &:after {
    position: absolute;
    z-index: 1;
    content: '';
    width: 16px;
    height: 16px;
    top: 3px;
    left: 0;
    transform-origin: left top;
    transform: scale(0,1);
    transition: transform 0.4s $ease_out;
    background-color: $text;
    pointer-events: none;
    } 
  &:hover {
    color: $text;
    &:after {
      transform-origin: right top;
      transform: scale(1,1);
    }
  }
}


a.area {
   position: absolute;
   z-index: 10;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   cursor: pointer;
   text-indent: -9999px;
   overflow: hidden;
   display: block;
}

@keyframes spin {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}
