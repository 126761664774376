.contact {
	padding: 40px 0;
	background-color: $gray-bgr;
  @media #{$up640}{
    padding: 50px 0;
  }
  @media #{$up1220}{
    padding: 80px 0;
  }
	.wrap {
		position: relative;
	}
	.btn--arrow {
		position: absolute;
		top: 0;
		right: 15px;
    @media #{$up640}{
      right: 0;
    }
		&:before {
      transform-origin: right top;
      transform: scale(1,1);
    }
    &:hover:before {
      transform-origin: left top;
      transform: scale(0,1);
    }
	}
	img {
		display: block;
		height: auto;
		width: 100%;
		max-width: 120px;
		margin: 0 0 40px;
    @media #{$up640}{
      max-width: 140px;
    }
    @media #{$up1220}{
      max-width: 168px;
    }
	}
	h3 {
    position: relative;
    margin: 0 0 30px;
		color: $brand;
		@include font(2.2);
		line-height: 1em;
		font-family: 'akrobatbold';
    @media #{$up640}{
      margin: 0;
    }
    @media #{$up768}{
      top: 3px;
      @include font(3.4);
    }
    @media #{$up1024}{
      @include font(4);
      top: 0;
    }
    @media #{$up1220}{
      @include font(4.9);
    }
	}
	.row--cols {
		padding-top: 20px;
    @media #{$up1024}{
      padding-top: 29px;
    }
    @media #{$up1220}{
      padding-top: 44px;
    }
		.row:last-child .col-2:nth-child(2) {
			margin: 0;
		}
	}
	.col:nth-child(1){
    @media #{$up640}{
		  width: 35%;
		  float: left;
    }
	}
	.col:nth-child(2){
    @media #{$up640}{
		  width: 60%;
		  float: left;
    }
	}
	.col-2:nth-child(1){
		width: 25%;
		float: left;
    @media #{$up768}{
      width: 15%;
    }
	}
	.col-2:nth-child(2){
		width: 65%;
		float: left;
		margin: 0 0 15px;
    @media #{$up768}{
      width: 75%;
    }
	}
}