.footer--copy {
  padding: 15px 0;
  background-color: $white;
  @media #{$up768}{
    padding: 20px 0;
  }
  @media #{$up1220}{
    padding: 25px 0;
  }
  .wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  p {
    margin: 0;
    @include font(1.4);
    a:hover {
      color: $brand;
    }
  }
}