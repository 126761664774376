@mixin bg-cover() {
	position: relative;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
}

@mixin grayscale(){
  filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
  filter: gray;
  -webkit-filter: grayscale(100%);
}
@mixin disable_grayscale(){
  filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
  -webkit-filter: grayscale(0%);
}

@mixin fit-cover() {
	width: 100%;
	height: 100%;
	max-width: none;
	max-height: none;
	font-family: "object-fit: cover;";
	object-fit: cover;
}

@mixin placeholder($color) {
	&::-webkit-input-placeholder {
		color: $color;
	}

	&:-moz-placeholder {
		color: $color;
	}

	&::-moz-placeholder {
		color: $color;
	}

	&:-ms-input-placeholder {
		color: $color;
	}
}

@mixin font($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}

@mixin smooth {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin bgr-alpha($color, $opacity) {
  background-color: rgba(red($color),green($color),blue($color), $opacity);
}

@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}