.header {
  position: relative;  
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 140px 0 15px 0;
  min-height: 280px;
  @media #{$up640}{
    padding: 200px 0 60px 0;
    min-height: 440px;
  }
  @media #{$up768}{
    min-height: 500px;
  }
  @media #{$up1024}{
    min-height: 600px;
  }
  @media #{$up1220}{
    min-height: 682px;
    padding: 230px 0 60px 0;
  }
  @media #{$up1600}{
    min-height: 844px;
    padding: 270px 0 60px 0;
  }
  h1 {
    margin: 0;
    line-height: 1em;
  }
  h2 {
    margin: .2em 0 .4em;
    @include font(2);
    font-family: 'akrobatlight';
    color: $brand;
    @media #{$up768}{
      @include font(2.6);
    }
    @media #{$up1024}{
      @include font(2.8);
    }
    @media #{$up1220}{
      @include font(3.2);
    }
  }

  &--gray {
    background-color: $gray-bgr;
  }

  &--section {
    padding: 30px 0;
    min-height: 0;
    @media #{$up640}{
      padding: 40px 0;
    }
    @media #{$up768}{

    }
    @media #{$up1220}{
      padding: 50px 0;
    }
    h2 {
      margin: 0;
      font-family: 'akrobatbold';
      color: $text;
      text-transform: uppercase;
      @include font(3);
      @media #{$up640}{
        @include font(5);
      }
      @media #{$up768}{
        @include font(7);
      }
      @media #{$up1220}{
        @include font(8);
      }
      @media #{$up1600}{
        @include font(10);
      }
    }
  }

  &--subsection {
    padding: 20px 0;
    min-height: 0;
    @media #{$up640}{
      padding: 30px 0;
    }
    @media #{$up1220}{
      padding: 40px 0;
    }
    h2 {
      position: relative;
      margin: 0;
      font-family: 'akrobatbold';
      color: $text;
      padding-left: 40px;
      @include font(2.2);
      @media #{$up640}{
        padding-left: 70px;
        @include font(4);
      }
      @media #{$up768}{
        
      }
      @media #{$up1220}{
        padding-left: 95px;
        @include font(7);
      }
      &:before {
        position: absolute;
        top: -3px;
        left: 0;
        width: 30px;
        height: 30px;
        background-color: $brand;
        content: '';
        @media #{$up640}{
          top: -1px;
          width: 50px;
          height: 50px;
        }
        @media #{$up768}{
          
        }
        @media #{$up1220}{
          top: 2px;
          width: 76px;
          height: 76px;
        }
      }
    }
  }

  &--offbot {
    padding-bottom: 60px;
    @media #{$up640}{
      padding-bottom: 80px;
    }
    @media #{$up1220}{
      padding-bottom: 100px;
    }
  }

}

.header__content {
  position: relative;
  display: inline-block;
  width: auto;
  max-width: calc(100% - 40px);
  left: 40px;
  padding: 15px 20px;
  background-color: rgba(255,255,255,0.9);
  @media #{$up640}{
    max-width: 458px;
  }
  @media #{$up768}{
    left: 60px;
    padding: 20px 30px;
    max-width: 546px;
  }
  @media #{$up1220}{
    max-width: 620px;
    left: 168px;
    padding: 30px 50px;
  }
  &:before {
    position: absolute;
    top: 0;
    left: -40px;
    width: 40px;
    height: 40px;
    background-color: $brand;
    content: '';
    @media #{$up768}{
      left: -60px;
      width: 60px;
      height: 60px;
    }
    @media #{$up1220}{
      left: -76px;
      width: 76px;
      height: 76px;
    }
  }
}