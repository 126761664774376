.cookies-consent  {
  position: fixed;
  z-index: 10000;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  display: none;
  background-color: $white;
  .wrap {
    position: relative;
  }
	p {
   @include font(1.5);
   margin: 0;
   padding: 0 50px 0 0;
   a {
    text-decoration: underline;
    @media #{$up640}{
      margin-top: 0;
    }
   }
   br {
    @media #{$up640}{
      display: none;
    }
   }
	}
  .btn {
    position: absolute;
    right: 0;
    top: -10px;
    padding: 0;
    width: 40px;
    height: 40px;
    line-height: 42px;
    i {
      position: relative;
      z-index: 2;
    }
  }
}
