.nav--archive {
  position: relative;
  z-index: 10;
  font-size: 0;
  text-align: center;
  @media #{$up1220}{
    text-align: left;
  }
  .btn {
    color: $text;
    background-color: $gray-bgr;
    @include font(1.6);
    font-family: 'akrobatregular';
    @media #{$up640}{
      @include font(2);
      line-height: 60px;
      height: 60px;
      padding: 0 35px;
    }
    @media #{$up768}{
      @include font(3);
      line-height: 80px;
      height: 80px;
      padding: 0 40px;
    }
    @media #{$up1220}{
      @include font(4.6);
      line-height: 100px;
      height: 100px;
      padding: 0 50px;
    }
    &:before {
      background-color: $brand;
    }
    &:hover {
      color: $white;
    }
    &--active {
      color: $brand;
      cursor: default;
      pointer-events: none;
      font-family: 'akrobatextrabold';
      &:before {
        transform-origin: right top;
        transform: scale(1,1);
        background-color: $white;
      }
      &:hover {
        color: $brand;
      }
    }
  }
}

.gallery {
	padding: 30px 0 40px;
	background-color: $white;
  @media #{$up640}{
    padding: 40px 0 60px;
  }
  @media #{$up1220}{
    padding: 60px 0 80px;
  }
	.header {
		padding-bottom: 20px !important;
    @media #{$up640}{
      padding-bottom: 40px !important;
    }
    @media #{$up1220}{
      padding-bottom: 60px !important;
    }
	}
}

.gallery-item {
	position: relative;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
  height: 0;
	width: 50%;
	width: calc(50% - 7.5px);
	padding: 0 0 50%;
	float: left;
	margin: 0 15px 15px 0;
	overflow: hidden;
	&:nth-child(2n){
		margin-right: 0;
	}
  @media #{$up640}{
    width: 33.33333%;
    width: calc(33.33333% - 10px);
    padding: 0 0 33.33333%;
    &:nth-child(2n){
      margin-right: 15px;
    }
    &:nth-child(3n){
      margin-right: 0;
    }
  }
  @media #{$up1024}{
    width: 25%;
    width: calc(25% - 11.5px);
    padding: 0 0 25%;
    &:nth-child(3n){
      margin-right: 15px;
    }
    &:nth-child(4n){
      margin-right: 0;
    } 
  }
	.area {
		&:before {
	    position: absolute;
	    z-index: 1;
	    content: '';
	    width: 100%;
	    height: 100%;
	    top: 0;
	    right: 0;
	    transform-origin: left top;
	    transform: scale(0,1);
	    transition: transform 0.35s $ease_out;
	    @include bgr-alpha($brand,0.8);
	    pointer-events: none;
	  }
	}
	&:hover .area:before {
		transform-origin: right top;
    transform: scale(1,1);
	}
}

.post {
  position: relative;
  padding: 40px 0 0;
  background-color: $white;
  overflow: hidden;
  @media #{$up640}{
    padding: 60px 0 0;
  }
  @media #{$up768}{
    padding: 70px 0 0;
  }
  @media #{$up1220}{
    padding: 80px 0 0;
  }
  h2 {
    margin: 0 0 .7em;
    color: $brand;
    @include font(2);
    font-family: 'akrobatbold';
    @media #{$up640}{
      @include font(2.4);
    }
    @media #{$up1220}{
      @include font(3);
    }
  }
  .wrap--header {
    header {
      padding: 0 0 30px;
      @media #{$up640}{
        padding: 0 0 50px;
      }
      @media #{$up768}{
        padding: 0 0 60px;
      }
      @media #{$up1220}{
        padding: 0 0 80px;
      }
      h1 {
        margin: 0 0 1em;
        color: $brand;
        @include font(1.8);
        font-family: 'akrobatbold';
        @media #{$up640}{
          @include font(2);
        }
        @media #{$up768}{
          margin: 0 0 .5em;
          @include font(2.4);
        }
        @media #{$up1220}{
          margin: 0 0 .25em;
          @include font(3);
        }
      }
      h2 {
        position: relative;
        margin: 0;
        padding-left: 40px;
        font-family: 'akrobatbold';
        color: $text;
        @include font(2.4);
        @media #{$up640}{
          @include font(3.4);
          padding-left: 60px;
        }
        @media #{$up768}{
          @include font(5);
          padding-left: 70px;
        }
        @media #{$up1220}{
          padding-left: 95px;
          @include font(7);
        }
        &:before {
          top: -2px;
          @media #{$up640}{
            top: -7px;
          }
          @media #{$up768}{
            top: 3px;
          }
        }
      }
    }
  }
}

.post__content {
  .col {
    position: relative;
    z-index: 1;
    padding: 0 0 20px;
  }
  .col:nth-child(1){
    @media #{$up768}{
      width: 60%;
      float: left;
      padding: 0 20px 60px 0;
    }
    @media #{$up1024}{
      width: 50%;
    }
    @media #{$up1220}{
      padding: 0 92px 60px 0;
    }
  }
  .col:nth-child(2){
    position: relative;
    z-index: 2;
    padding: 0 0 5px;
    @media #{$up640}{
      padding: 0 0 40px;
    }
    @media #{$up768}{
      width: 40%;
      float: left;
      padding: 0 0 40px 30px;
    }
    @media #{$up1024}{
      width: 50%;
      padding: 0 0px 0 92px;
    }
    @media #{$up1220}{
      padding: 0 100px 60px 0;
    }
    img {
      display: block;
      width: 100%;
      height: auto;
      margin: 10px auto;
      @media #{$up1024}{
        margin: 0 auto 60px;
      }
    }
  }
  h3 {
    position: relative;
    margin: 0 0 .7em;
    padding: 0 0 .7em;
    line-height: 1.3em;
    @include font(2);
    @media #{$up640}{
      @include font(2.4);
    }
    @media #{$up1220}{
      @include font(3);
    }
    &:after {
      position: absolute;
      width: 10000px;
      height: 2px;
      content: '';
      background-color: $gray-bgr;
      bottom: 0;
      left: -3500px;
    }
  }
  h4 {
    margin: 0 0 1em;
    line-height: 1.3em;
    font-family: 'akrobatregular';
    font-weight: 400;
    @include font(1.8);
    @media #{$up640}{
      @include font(2.2);
    }
    @media #{$up1220}{
      @include font(2.8);
    }
  }
  p {
    margin: 0 0 1.4em;
    line-height: 1.4em;
    a {
      font-family: 'akrobatbold';
    }
  }
}

.post__links {
  padding: 40px 0;
  border-top: 2px solid $gray-bgr;
  @media #{$up640}{
    padding: 50px 0;
  }
  @media #{$up1220}{
    padding: 60px 0;
  }
  ul {
    margin: 0;
    list-style: none;
    li {
      margin: 0 0 .4em;
      &:last-child {
        margin: 0;
      }
    }
  }
}