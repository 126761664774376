.navbar {
  position: absolute;
  z-index: 1000;
  width: 100%;
  padding: 15px;
  top: 0;
  left: 0;
  text-align: right;
  @media #{$up640}{
    padding: 20px 0;
    border-bottom: 2px solid $white;
  }
  @media #{$up1600}{
    padding: 40px 0;
  }
  &.open {
    .btn--menu {
      color: $brand;
      i:before {
        content: "\e905";
      }
      &:before {
        transform-origin: right top;
        transform: scale(1,1);
      }
    }
    .navbar__btns {
      .btn--register {
        display: inline-block;
      }
    }
    .navbar__menu {
      transform: translate(0,0) translateZ(0);
    }
  }

  .wrap {
    @media #{$up640}{
      position: relative;
    }
  }

}

.navbar__logo {
  position: relative;
  float: left;
  display: inline-block;
  overflow: hidden;
  width: 110px;
  height: 85px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../images/zfg-logo.svg);
  @media #{$up640}{
    width: 140px;
    height: 108px;
  }
  @media #{$up1220}{
    width: 168px;
    height: 140px;
  }
  span {
    opacity: 0;
  }
}

.navbar__btns {
  position: fixed;
  z-index: 2000;
  top: 15px;
  right: 15px;
  display: inline-block;
  @media #{$up640}{
    right: 50%;
    top: 60px;
    margin-right: -280px;
  }
  @media #{$up768}{
    margin-right: -340px;
  }
  @media #{$up1024}{
    margin-right: -460px;
  }
  @media #{$up1220}{
    top: 64px;
    margin-right: -544px;
  }
  @media #{$up1600}{
    margin-right: 0;
    top: 84px;
    right: auto;
  }
  .btn--register {
    display: none;
    top: 1px;
    @media #{$up640}{
      display: inline-block;
    }
    @media #{$up768}{
      
    }
    @media #{$up1220}{
      
    }
    em {
      position: relative;
      z-index: 10;
      @include font(1.2);
      display: block;
    }
  }
}

.navbar__menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: $text;
  color: $white;
  text-align: left;
  padding: 90px 0 30px;
  font-family: 'akrobatextrabold';
  @include font(2);
  transition: transform 0.3s $ease_out;
  transform: translate(100%, 0) translateZ(0);
  @media (min-height: 480px){
    padding: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  @media #{$up640}{
    width: 50%;
    @include font(2.4);
  }
  @media #{$up768}{
    @include font(3); 
  }
  @media #{$up1220}{
    @include font(3.4);
    padding: 0 5%;
  }
  @media #{$up1600}{
    @include font(4);
  }
  ul {
    width: 100%;
    padding: 0 15px 0 40px;
    @media #{$up1220}{
      width: calc(100% - 92px);
    }
  }
  li {
    position: relative;
    display: block;
    width: 100%;
    margin: 15px 0;
    @media (min-height: 480px){
      margin: 3vh 0;
    }
    &:before {
      position: absolute;
      z-index: 1;
      content: '';
      width: 30px;
      height: 30px;
      top: 0;
      left: 0;
      background-color: $white;
      pointer-events: none;
      @media #{$up768}{
        width: 54px;
        height: 54px;
      }
    }
    &:after {
      position: absolute;
      z-index: 1;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      transform-origin: left top;
      transform: scale(0,1);
      transition: transform 0.4s $ease_out;
      background-color: $brand;
      pointer-events: none;
    }
    &:hover {
      &:after {
        transform-origin: right top;
        transform: scale(1,1);
      }
    }
  }
  a {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    padding: 0 0 0 40px;
    line-height: 30px;
    @media #{$up768}{
      padding: 0 0 0 72px;
      line-height: 54px;
    }
  }
}