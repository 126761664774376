[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
.content-editable,
textarea,
select {
	display: block;
	margin: 0 0 15px;
	height: 40px;
	width: 100%;
	padding: 0 15px;
	resize: none;
	font-weight: 400;
  border: none;
  border-radius: 0;
	transition: border .12s linear; 
  @include font(1.5);
  font-family: 'akrobatbold';
  @media #{$up768}{
   height: 50px; 
   padding: 0 20px;
   @include font(1.6);
  }
  @media #{$up1220}{
    height: 52px;
    margin: 0 0 20px;
    padding: 0 25px;
    @include font(2);
  }
	@include placeholder {
		opacity: 1;
	}
	&:focus {
		
	}
	&.error {
		
	}
}

textarea {
  height: 140px;
	padding: 15px; 
	resize: none;
  @media #{$up768}{
    height: 180px;
    padding: 20px;
  }
  @media #{$up1220}{
    height: 220px;
    padding: 25px;
  }
}

label {
  display: inline-block;
  margin: 0 0 .25em;
  color: $brand;
  font-family: 'akrobatbold';
	&.error {
    position: absolute;
    left: 0;
    bottom: 0;
		font-size: 0;
    width: 4px;
    height: 40px;
    background-color: red;
    @media #{$up768}{
      height: 50px;
      width: 5px;
    }
    @media #{$up1220}{
      height: 52px;
      width: 6px;
    }
	}
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;

  input,
  textarea,
  .file-upload__name {
    border: 1px solid #BBBBBB !important;
  }

  &.gray {
    background-color: $gray-bgr;
    input,
    textarea,
    .file-upload__name {
      border: none !important;
    }
  }
}

.file-upload {
  position: relative;
  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  .btn {
    position: absolute;
    z-index: 10;
    bottom: 0;
    right: 0;
    line-height: 40px;
    height: 40px;
    @media #{$up768}{
      line-height: 50px;
      height: 50px;
    }
    @media #{$up1220}{
      line-height: 52px;
      height: 52px;
    }
  } 
  .textarea-limit {
    position: absolute;
    bottom: -25px;
    right: 0;
    width: 100%;
    top: auto;
  }
}
.file-upload__name {
  height: 40px;
  line-height: 40px;
  width: 100%;
  padding: 0 15px;
  font-weight: 400;
  transition: border .12s linear; 
  @include font(1.5);
  white-space: nowrap;
  overflow: hidden;
  font-family: 'akrobatbold';
  @media #{$up768}{
   height: 50px; 
   line-height: 50px; 
   padding: 0 20px;
   @include font(1.6);
  }
  @media #{$up1220}{
    height: 52px;
    line-height: 52px;
    margin: 0 0 20px;
    padding: 0 25px;
    @include font(2);
  }
}

button[type='submit']{
	min-width: 200px;
  width: 100%;
  text-transform: none;
  font-family: 'akrobatextrabold';
  @media #{$up640}{
    width: auto;
  }
  @media #{$up1220}{
    min-width: 300px;
    line-height: 76px;
    height: 76px;
    @include font(2.4);
  }
}

.form-submit {
	position: relative;
  text-align: center;
  padding: 60px 0;
  border-top: 2px solid $gray-bgr;
  @media #{$up768}{
    text-align: left;
  }
  @media #{$up1220}{
    padding: 60px 0 60px 92px;
  }
  button {
    @media #{$up640}{
      float: right;
    }
  }
	.msg,
  .msg-error,
  .msg-captcha {
    display: none;
		margin: 0;
		padding: 15px 0;
    text-align: center;
    vertical-align: middle;
    @media #{$up768}{
      width: auto;
      padding: 0 0 60px 25px;
    }
		&.show {
			display: block;
		}
    p {
      font-family: 'akrobatbold';
      color: $brand;
      @media #{$up1220}{
        @include font(2.4);
      }
    }
	}
  .msg-error p,
  .msg-captcha p {
    color: red;
  }
}

.form-note {
  padding: 60px 0;
  border-top: 2px solid $gray-bgr;
  @media #{$up1220}{
    padding: 60px 0 60px 92px;
  }
  p {
    line-height: 1.4em;
  }
}

.textarea-limit {
  position: relative;
  top: -15px;
  @include font(1.4);
  text-align: right;
}

.captcha {
  margin: 0 0 25px;
  @media #{$up640}{
    float: left;
    margin: 0;
  }
}

form {

}