h1,
h2,
h3,
h4 {
	margin: 0 0 .4em;
	line-height: 1.2em;
}
h1 {
  font-family: 'akrobatblack';
  @include font(2.6);
	@media #{$up768}{
	 @include font(4);
	}
  @media #{$up1024}{
  @include font(5);
  }
	@media #{$up1220}{
		@include font(6);
	}
  @media #{$up1600}{
    @include font(6.8);
  }
}
h2 {
  font-family: 'akrobatbold';
  @include font(2.4);
  @media #{$up640}{
    @include font(2.8);
  }
	@media #{$up768}{
	 @include font(4);
	}
  @media #{$up1024}{
    @include font(5);
  }
	@media #{$up1220}{
    @include font(7); 
	}
	@media #{$up1600}{
	 @include font(7);
	}
}
h3 {
  font-family: 'akrobatbold';
	@media #{$up768}{
	
	}
	@media #{$up1220}{
	 @include font(2.4)
	}
}
h4 {
  
  
	@media #{$up1220}{
    
  }
} 


em {
	font-style: normal;
}

p {
	margin: 0 0 1em;
	line-height: 1.3em;
	@include font(1.5);
	@media #{$up768}{
	 @include font(1.6);
	}
  @media #{$up1220}{
    @include font(1.8);
  }
	@media #{$up1600}{

	}
	&:last-child {
		margin: 0 !important;
	}
  a:hover {
    color: $brand;
  }
}