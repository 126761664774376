.wrap {
	margin: 0 auto;
	width: 100%;
	@media #{$up640}{
		max-width: 560px;
	}
	@media #{$up768}{
		max-width: 680px;
	}
	@media #{$up1024}{
		max-width: 920px;
	}
	@media #{$up1220}{
		max-width: 1088px;
	}
	&--inner {
		padding:0 15px;
		@media #{$up640}{
			padding: 0;
		}
	}
}

.animate {
  opacity: 0;
  transform: translate(0,30px);
  &.in {
    transition: transform 2s .3s $ease_transform, opacity .6s .4s linear;
    opacity: 1;
    transform: translate(0,0);
  }
}

.scroll-down {
  position: absolute;
  z-index: 1000;
  bottom: 20px;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  background-size: 100%;
  background-image: url(../images/icons/arrow-down.svg);
  background-position: center;
  animation: bounce 2.5s infinite;
  display: none;
  @media #{$up768}{
    display: inline-block;
  }
  @media #{$up1220}{
    bottom: 30px;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-5px);
  }
}
