@font-face {
    font-family: 'akrobatblack';
    src: url('../fonts/akrobat/akrobat-black-webfont.woff2') format('woff2'),
         url('../fonts/akrobat/akrobat-black-webfont.woff') format('woff'),
         url('../fonts/akrobat/akrobat-black-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'akrobatbold';
    src: url('../fonts/akrobat/akrobat-bold-webfont.woff2') format('woff2'),
         url('../fonts/akrobat/akrobat-bold-webfont.woff') format('woff'),
         url('../fonts/akrobat/akrobat-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'akrobatextrabold';
    src: url('../fonts/akrobat/akrobat-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/akrobat/akrobat-extrabold-webfont.woff') format('woff'),
         url('../fonts/akrobat/akrobat-extrabold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'akrobatextralight';
    src: url('../fonts/akrobat/akrobat-extralight-webfont.woff2') format('woff2'),
         url('../fonts/akrobat/akrobat-extralight-webfont.woff') format('woff'),
         url('../fonts/akrobat/akrobat-extralight-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'akrobatlight';
    src: url('../fonts/akrobat/akrobat-light-webfont.woff2') format('woff2'),
         url('../fonts/akrobat/akrobat-light-webfont.woff') format('woff'),
         url('../fonts/akrobat/akrobat-light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'akrobatregular';
    src: url('../fonts/akrobat/akrobat-regular-webfont.woff2') format('woff2'),
         url('../fonts/akrobat/akrobat-regular-webfont.woff') format('woff'),
         url('../fonts/akrobat/akrobat-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'akrobatsemibold';
    src: url('../fonts/akrobat/akrobat-semibold-webfont.woff2') format('woff2'),
         url('../fonts/akrobat/akrobat-semibold-webfont.woff') format('woff'),
         url('../fonts/akrobat/akrobat-semibold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'akrobatthin';
    src: url('../fonts/akrobat/akrobat-thin-webfont.woff2') format('woff2'),
         url('../fonts/akrobat/akrobat-thin-webfont.woff') format('woff'),
         url('../fonts/akrobat/akrobat-thin-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}






@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/icomoon.eot?eza7ut');
  src:  url('../fonts/icomoon/icomoon.eot?eza7ut#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf?eza7ut') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?eza7ut') format('woff'),
    url('../fonts/icomoon/icomoon.svg?eza7ut#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-arrow-up:before {
  content: "\e903";
}
.icon-check:before {
  content: "\e904";
}
.icon-close:before {
  content: "\e905";
}
.icon-facebook:before {
  content: "\e906";
}
.icon-google:before {
  content: "\e907";
}
.icon-instagram:before {
  content: "\e908";
}
.icon-linkedin:before {
  content: "\e909";
}
.icon-menu:before {
  content: "\e90a";
}
.icon-share:before {
  content: "\e90b";
}
.icon-twitter:before {
  content: "\e90c";
}
.icon-youtube:before {
  content: "\e90d";
}
.icon-youtube2:before {
  content: "\e90e";
}
