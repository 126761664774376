html {
	font-size: 62.5%;
	overflow-y: scroll;
} 
body {
	width: 100%;
	max-width: 100vw;
	@include font(1.5);
	text-rendering: optimizeLegibility;
	@include smooth();
  font-weight: 400;
	font-family: 'akrobatregular';
  color: $text;
  background-color: $gray-bgr;
  @media #{$up768}{
   @include font(1.6);
  }
  @media #{$up1220}{
    @include font(1.8);
  }
}

figure {
	margin: 0;
}

nav {
	ul {
		list-style: none;
	}
	li,
	a {
		display: inline-block;
		vertical-align: middle;
	}
}

* {
	outline: none;
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0); 
	-webkit-tap-highlight-color: transparent; 
  background-repeat: no-repeat;
}

a,
button {
	text-decoration: none;
	color: inherit;
	cursor: pointer;
	transition: all .2s linear;
	transition-property: color, background, border, opacity;
	&:hover {
		
	}	
}
button {
	cursor: pointer;
	border-radius: 0;
	background: none;
	border: none;
	display: inline-block;
	vertical-align: middle;
	padding: 0;
}

address {
	font-style: normal;
}

ul,
ol {
	margin:0;
	padding:0;
}


.page {
	width: 100%;
	min-height: 100vh;
}

main {
	display: block;
  overflow: hidden;
  transition: opacity .25s linear;
  &.out {
    opacity: .05;
  }
}

.no-js .anim {
	visibility: visible !important;
}

.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.clearfix:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
}
.clearfix {
    display: inline-block;
}
.clearfix {
    display: block;
}

::-moz-selection {
	text-shadow:none;
	background-color: $brand;
	color: $white;
}
::selection {
	text-shadow:none;
	background-color: $brand;
	color: $white;
}


.nicescroll-rails {
  opacity: 0 !important;
}