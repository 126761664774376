.post--about {
	.post__content {
	  .col {
	    @media #{$up1220}{
        padding: 0 100px 60px 0;
      }
	  }
	  .col:nth-child(1){
	  	@media #{$up1220}{
        width: 58%;
      }
	  }
	  .col:nth-child(2){
	  	@media #{$up1220}{
        width: 42%;
      }
	  }
	}
}

.post--text {
  .post__content {
    .col:nth-child(1) {
      float: none;
      width: 100%;
      padding: 0 0 40px;
      margin: 0 auto;
      @media #{$up768}{
        max-width: 560px;
        padding: 0 0 80px;
      }
      @media #{$up1024}{
        max-width: 800px;
      }
      @media #{$up1220}{
        max-width: 750px;
      }
    }
  }
  ul,
  ol {
    margin: 0 0 1em;
    list-style-position: inside;
    li {
      margin: 0 0 1em;
    }
  }
}

.why {
	padding: 40px 0;
	background-color: $white;
	border-top: 2px solid $gray-bgr;
  @media #{$up640}{
    padding: 50px 0 60px;
  }
  @media #{$up1220}{
    padding: 60px 0 80px;
  }
	.col {
    padding: 30px 0 0;
		@media #{$up640}{
      width: 45%;
      float: left;
      padding: 60px 0 0 40px;
      &:nth-child(odd){
        clear: left;
      }
      &:nth-child(even){
        float: right;
      }
    }
	}
	h2 {
		margin: 0 0 .15em;
		line-height: 1.2em;
    @media #{$up768}{
      line-height: 1.1em;
    }
    @media #{$up1220}{
      line-height: 1em;
    }
		em {
			color: $brand;
		}
	}
	h3 {
		@include font(2);
		font-weight: 400;
		font-family: 'akrobatregular';
    @media #{$up640}{
      @include font(2.2);
    }
    @media #{$up768}{
      @include font(2.4);
    }
    @media #{$up1220}{
      @include font(3);
    }
	}
	h4 {
		position: relative;
		@include font(1.8);
		line-height: 1em;
		font-family: 'akrobatblack';
    padding-left: 35px;
    @media #{$up640}{
      padding: 0;
      @include font(2);
    }
    @media #{$up768}{
      @include font(2.4);
    }
    @media #{$up1220}{
      @include font(3);
    }
	 	&:before {
      position: absolute;
      top: -3px;
      left: 0;
      width: 24px;
      height: 24px;
      background-color: $brand;
      content: '';
      @media #{$up640}{
        left: -40px;
      }
      @media #{$up768}{
        top: 2px;
      }
    }
	}
	p {
    @media #{$up768}{
      @include font(1.8);
    }
		@media #{$up1220}{
      @include font(2);
    }
	}
}