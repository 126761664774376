.partners {
	background-color: $white;
	padding: 20px 0 0;
  text-align: center;
  @media #{$up768}{
    padding: 20px 0 0;
    text-align: left;
  }
  @media #{$up1220}{
    padding: 40px 0 0;
  }
	header {
		background-color: $white;
	}
	ul {
		padding: 10px 0 50px;
		list-style: none;
	
	}
	li {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50%;
		float: left;
		text-align: center;
		margin: 20px 0;
		&:nth-child(2n-1){
			clear: left;
		}
    @media #{$up640}{
      margin: 30px 0;
    }
    @media #{$up768}{
      width: 33.33333%;
      &:nth-child(2n-1){
        clear: none;
      } 
      &:nth-child(3n-2){
        clear: left;
      } 
    }
    @media #{$up1220}{
      width: 25%;
      &:nth-child(3n-2){
        clear: none;
      } 
      &:nth-child(4n-3){
        clear: left;
      } 
    }
	}
	img {
		display: block;
		margin: 0;
		width: auto;
    height: auto;
		max-width: 100px;
    max-height: 40px;
    @media #{$up640}{
      max-width: 160px;
      max-height: 60px;
    }
    @media #{$up1220}{
      max-width: 100%;
      max-height: none;
    }
	}
  h2 {
    margin: 0;
    padding: 20px 0 0;
    @include font(2.2);
    @media #{$up768}{
      @include font(3.2);
    }
    @media #{$up1220}{
      @include font(4);
    }
    @media #{$up1600}{
      @include font(4.4);
    }
  }
}