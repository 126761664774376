.speaker--profile {
	position: relative;
  width: 100% !important;
  max-width: 100% !important;
	padding: 140px 0 60px !important;	
	margin: 0;
	background-color: $white;
  @media #{$up640}{
    padding: 270px 0 50px 0 !important;
  }
  @media #{$up1600}{
    padding: 280px 0 60px !important; 
  }
	&:before {
		position: absolute;
		width: 100%;
		height: 85px;
		content: '';
		background-color: $gray-bgr;
		bottom: 0;
		left: 0;
    @media #{$up1220}{
      height: 152px;
    }
	}
	&:after {
		bottom: auto;
		top: 222px;
	}

	.speaker__dsc {
		padding-bottom: 0;
		p {
			@include font(1.8);
			font-family: 'akrobatlight';
			font-weight: 400;
      @media #{$up640}{
        @include font(2);
      }
      @media #{$up768}{
        @include font(2.4);
      }
      @media #{$up1220}{
        @include font(3);
      }
		}
	}
	.speaker__data {
		padding-top: 0;
		padding-bottom: 40px;
		h4 {
			margin: 0 0 30px;
			@include font(2.4);
      @media #{$up640}{
        @include font(2.8);
      }
      @media #{$up768}{
        @include font(3.2);
      }
      @media #{$up1220}{
        @include font(3.8);
      }
		}
		p,
		a {
			@include font(1.6);
      @media #{$up768}{
        @include font(2);
      }
		}
	}
}

.speaker--description {
	margin: 0;
	padding: 40px 0;
  max-width: 100% !important;
	background-color: $white;
  @media #{$up640}{
    padding: 60px 0;
  }
  @media #{$up1220}{
    padding: 80px 0;
  }
	&:after {
		display: none;
	}
	.wrap {
		display: block;
		@media #{$up768}{
      column-count: 2;
      column-gap: 60px;
    }
    @media #{$up1220}{
    	padding-left: 92px;
    }
	}
}