// COLORS
$white: #ffffff;
$black: #5D5D5D;
$brand: #51C7D9;
$text: #005785;
$gray-bgr: #F0F0F0;
$gray-text: #B1B1B1;

//BORDERS
$border: 1px solid #E6E6E8; 

//EASING
$ease_snap: cubic-bezier(0,.56,.76,1.3);
$ease_expo:cubic-bezier(1, 0, 0.7, 0.89);
$ease_out: cubic-bezier(.4,.2,.2,1);
$ease_transform: cubic-bezier(0.19, 1, 0.22, 1);

//BREAKPOINTS
$up640: "only screen and (min-width: 640px)";
$up768: "only screen and (min-width: 768px)";
$up1024: "only screen and (min-width: 1024px)";
$up1220: "only screen and (min-width: 1220px)"; 
$up1400: "only screen and (min-width: 1400px)";
$up1600: "only screen and (min-width: 1600px)";